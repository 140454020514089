import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import * as dayjs from 'dayjs'
import Layout from '../components/layout'
import SEO from '../components/seo'
import RichTextRenderer from '../components/rich-text-renderer'
import { SectionHeader, Section } from '../styles/styles'

const Event = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Changes to single column layout */
  }
`

const EventImage = styled.img`
  width: 100%;
`

const EventDescription = styled.div`
  p {
    margin-bottom: 0;
  }
`

const Specials = styled.div`
  p {
    margin: 0;
  }
`

const Divider = styled.hr`
  margin: 2rem 0;
  border: 0;
  border-top: 1px solid #444;
`

function groupEventsByMonth(events) {
  const eventsByMonth = {}
  const monthOrder = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  events.forEach(event => {
    const eventDate = dayjs(event.eventDate)
    const now = dayjs()
    const next3Months = [
      now.format('MMMM'),
      now.add(1, 'month').format('MMMM'),
      now.add(2, 'month').format('MMMM')
    ]

    if (!event.recurring && eventDate.isBefore(now)) {
      return
    }

    if (event.recurring) {
      event.recurringMonths.forEach(month => {
        if (!next3Months.includes(month)) return

        if (!eventsByMonth[month]) {
          eventsByMonth[month] = []
        }

        const eventDay = eventDate.day()
        const eventMonthDate = now.set('month', monthOrder.indexOf(month))
        const firstDayOfMonth = eventMonthDate.startOf('month').day()

        let dayDifference = 0
        if (eventDay < firstDayOfMonth) {
          dayDifference = 7 - firstDayOfMonth + eventDay
        } else if (eventDay > firstDayOfMonth) {
          dayDifference = 7 - (7 - eventDay) - firstDayOfMonth
        }

        let dupDate = eventMonthDate.startOf('month').add(dayDifference, 'days')

        // console.log(dupDate.month(), eventMonthDate.month())

        while (dupDate.month() === eventMonthDate.month()) {
          // console.log(dupDate.format())
          const dupEvent = { ...event }
          dupEvent.eventDate = dupDate.format()

          if (!dupDate.isBefore(now)) {
            eventsByMonth[month].push(dupEvent)
          }

          dupDate = dupDate.add(7, 'days')
        }
      })
    } else {
      const month = new Date(event.eventDate).toLocaleString('default', {
        month: 'long'
      })

      if (!next3Months.includes(month)) return

      if (!eventsByMonth[month]) {
        eventsByMonth[month] = []
      }
      eventsByMonth[month].push(event)
    }

    // if (event.eventDate) {
    //   const day = dayjs(event.eventDate).format('dddd')
    //   event.day = day
    // }
  })

  // Sort the eventsByMonth object by month order
  const sortedEventsByMonth = Object.keys(eventsByMonth)
    .sort((a, b) => monthOrder.indexOf(a) - monthOrder.indexOf(b))
    .reduce((acc, month) => {
      acc[month] = eventsByMonth[month].sort((a, b) =>
        dayjs(a.eventDate).isBefore(dayjs(b.eventDate)) ? -1 : 1
      )
      return acc
    }, {})

  return sortedEventsByMonth
}

const Happenings = ({ data }) => {
  const { specials, events, title } = data.contentfulHappeningsPage

  const groupedEvents = groupEventsByMonth(events)

  return (
    <Layout>
      <SEO title="Happenings" />
      <h1>{title}</h1>
      <SectionHeader>Daily Specials</SectionHeader>
      <Section>
        <Specials>
          <RichTextRenderer json={specials.json} />
        </Specials>
        {/* <div>{dailySpecials}</div> */}
      </Section>
      {/* <SectionHeader>Events</SectionHeader> */}
      {/* <p dangerouslySetInnerHTML={{ __html: processDescription }}></p> */}
      <div>
        {Object.keys(groupedEvents).map(month => (
          <div key={month} style={{ marginBottom: '2rem' }}>
            <h2 style={{ marginBottom: 5 }}>{month}</h2>
            <Section>
              {groupedEvents[month]
                // .sort((a, b) => dayjs(b.eventDate).isBefore(dayjs(a.eventDate)))
                .map((evt, index) => (
                  <div key={evt.eventTitle}>
                    <Event>
                      <EventImage src={evt.eventImage.fluid.src} />
                      <EventDescription>
                        <h4>{evt.eventTitle}</h4>
                        <h4 style={{ fontSize: '1.2rem' }}>
                          {/* {evt.recurring
                          ? `Every ${evt.day}`
                          : `${dayjs(evt.eventDate).format(
                              'dddd, MMMM D @ h:mm A'
                            )}`} */}
                          {dayjs(evt.eventDate).format('dddd, MMMM D @ h:mm A')}
                        </h4>
                        <p>
                          {evt.eventDescriptionNew ? (
                            <RichTextRenderer
                              json={evt.eventDescriptionNew.json}
                            />
                          ) : (
                            evt.eventDescription.eventDescription
                          )}
                        </p>
                      </EventDescription>
                    </Event>
                    {index < groupedEvents[month].length - 1 && <Divider />}
                  </div>
                ))}
            </Section>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default Happenings

export const query = graphql`
  query HappeningsQuery {
    contentfulHappeningsPage {
      title
      events {
        eventTitle
        eventDescriptionNew {
          json
        }
        eventDescription {
          eventDescription
        }
        eventDate
        recurring
        recurringMonths
        eventImage {
          fluid(maxWidth: 1024) {
            src
            srcSet
            sizes
            srcSetWebp
            srcWebp
            base64
            aspectRatio
          }
        }
      }
      specials {
        json
      }
    }
  }
`
